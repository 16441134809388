.container {
  display: grid;
  height: 60vh;
  grid-template-columns: 100%;
  grid-template-rows: 15vh 45vh;
  grid-template-areas:
    "nav"
    "hero";
}

.backgroundimage::before {
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  /*   -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); */
  pointer-events: none; /* make the pseudo class click-through */
}

.backgroundimage {
  position: relative;
  width: 100%;
  height: 60vh;
  background: no-repeat center center;
  background-image: url("./landing-image.webp");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  top: 0;
  position: absolute;
  z-index: -2;
}

.nav {
  grid-area: nav;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.hero {
  grid-area: hero;
  /* background-image: url(./hero2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.main {
  display: none;
  background-color: rgba(255, 238, 238, 0.75);
  flex-direction: column;
  width: 90%;
  justify-content: center;
  margin: 40px;
  padding: 20px;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  display: none;
}

.navlogo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.brandname {
  display: none;
}

.navbrandname {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.header {
  font-family: var(--header-font);
  color: var(--secondary-color);
  font-size: 2rem;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 0;
}

.learn {
  display: none;
  text-align: center;
  justify-content: center;
  padding: 10px 15px;
  width: 100px;
  color: var(--primary-color);
  /* border: 3px solid var(--logo-color); */
  text-decoration: none;
  background-color: var(--logo-color);
  font-weight: bold;
  margin-top: 0px;
  border-radius: 2px;
  transition-property: all;
  transition-duration: 0.2s;
}

.dashboard {
  margin-right: 15px;
  border: none;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  width: 120px;
  font-size: 16px;
  font-family: var(--body-font);
  cursor: pointer;
}

.learn:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  background-color: var(--secondary-logo-color);
  color: var(--secondary-color);
  letter-spacing: 1px;
}

.learn:active {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  background-color: var(--secondary-logo-color);
  color: var(--secondary-color);
  letter-spacing: 1px;
}

.learn:hover {
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  background-color: var(--secondary-logo-color);
  color: var(--secondary-color);
  letter-spacing: 1px;
}

.learnmobile {
  text-align: center;
  justify-content: center;
  padding: 10px;
  width: 100px;
  color: var(--primary-color);
  text-decoration: none;
  background-color: var(--logo-color);
  font-weight: bold;
  margin-top: 0px;
  letter-spacing: 1px;
}

.learnmobile:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.learnmobile:hover {
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.explore {
  color: var(--secondary-color);
  text-decoration: none;
  background-color: var(--primary-color);
}

.button {
  border: none;
  padding: 10px;
  width: 75px;
  margin: 5px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.button:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.footersignup {
  color: #9ca6af;
  font-size: 16px;
  background: transparent;
  border: none;
  border-bottom: 3px solid var(--logo-color);
  padding: 0px;
  cursor: pointer;
}

.footersignup:focus {
  outline: none;
}

.dashboardservices {
  display: none;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dashboardservice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  min-width: 30%;
}

.dashboardserviceicon {
  display: flex;
}

.dashboardservicesmobile {
  display: block;
  text-align: center;
  font-family: var(--header-font);
  margin-bottom: 20px;
}

/*****Services*****/

.services {
  background-color: #fff;
  min-height: 60vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service {
  margin-top: 20px;
  align-items: flex-start;
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  width: 90vw;
}

.servicedetail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffeeee;
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  border-top: 6px solid var(--logo-color);
  padding: 20px;
  margin: 10px;
}

/******Partners*****/

.partners {
  background-color: #fff;
  min-height: 50vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.partnerdetails {
  margin-top: 20px;
  align-items: flex-start;
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partner {
  border-left: 4px solid var(--logo-color);
  margin: 10px;
  background-color: #ffeeee;
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  width: 100%;
}

/******Pricing*****/

.pricing {
  background-color: #ffeeee;
  min-height: 40vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.pricingdetails {
  margin-top: 20px;
  align-items: flex-start;
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/********Radiologists********/

.radiologists {
  background-color: #ffeeee;
  min-height: 80vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.radiologistdetails {
  margin-top: 20px;
  align-items: flex-start;
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.otherradiologistdetails {
  margin-top: 20px;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

/****Prem****/

.premimage {
  border-radius: 50%;
}

.prem {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
}

.premless {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
  height: 375px;
}

.premreg {
  font-style: italic;
  text-align: center;
}

.prembio {
  margin-bottom: 20px;
  text-align: center;
}

.prembioless {
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.prembiocontent {
  margin-top: 20px;
}

.prembutton {
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  background-color: #ffeeee;
  letter-spacing: 1px;
  padding: 5px;
  border: none;
  height: 25px;
}

/****Prem****/

/*****VijayAnand*****/
.vijayanandimage {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.vijayanand {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
}

.vijayanandless {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
  height: 375px;
}

.vijayanandreg {
  font-style: italic;
  text-align: center;
}

.vijayanandbio {
  margin-bottom: 20px;
  text-align: center;
}

.vijayanandbioless {
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.vijayanandbiocontent {
  margin-top: 20px;
}

.vijayanandbutton {
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  background-color: #ffeeee;
  letter-spacing: 1px;
  padding: 5px;
  border: none;
  height: 25px;
}

/*****VijayAnand*****/

/*****Kiran*****/

.kiranimage {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.kiran {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
}

.kiranless {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
  height: 375px;
}

.kiranreg {
  font-style: italic;
  text-align: center;
}

.kiranbio {
  margin-bottom: 20px;
  text-align: center;
}

.kiranbioless {
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.kiranbiocontent {
  margin-top: 20px;
}

.kiranbutton {
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  background-color: #ffeeee;
  letter-spacing: 1px;
  padding: 5px;
  border: none;
  height: 25px;
}

/*****Kiran*****/

.radiologistimage {
  border-radius: 50%;
}

.radiologistimagesmall {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.otherradiologistimagesmall {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.radiologistbio {
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.radiologistbiocontent {
  margin-top: 20px;
}

.radiologistreg {
  font-style: italic;
  text-align: center;
}

.otherradiologistreg {
  font-style: italic;
  text-align: center;
}

.otherradiologistbio {
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.radiologistbiocontent {
  margin-top: 5px;
}

.radiologist {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
}

.radiologistless {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
  height: 350px;
}

.otherradiologist {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--logo-color);
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
  padding: 20px;
  width: 100%;
  margin: 10px;
  height: 150px;
}

/******Contact******/

.contactdetails {
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  flex-direction: column;
}

.contact {
  background-color: #faf9f8;
  min-height: 40vh;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.address {
  margin-bottom: 20px;
  text-align: center;
}

.email {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.phone {
  text-decoration: none;
  color: var(--primary-color);
}

.phone:visited {
  color: var(--primary-color);
}

/******Contact******/

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

  .container {
    height: 100vh;
    grid-template-rows: 10vh 90vh;
  }

  .container:before {
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .backgroundimage {
    height: 100vh;
  }

  .nav {
    padding: 20px 120px 0px 120px;
    justify-content: flex-end;
  }

  .hero {
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .main {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    margin: 40px;
    padding: 20px;
  }

  .brand {
    flex-direction: row;
    padding-right: 50px;
    /* border-right: 5px solid var(--logo-color); */
    min-height: 200px;
    margin-top: 0px;
  }

  .brandname {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbrandname {
    display: none;
  }

  .header {
    font-size: 7rem;
    color: var(--primary-color);
  }

  .caption {
    font-size: 30px;
  }

  .logo {
    display: block;
    width: 100px;
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .learn {
    display: block;
  }

  .learnmobile {
    display: none;
  }

  .navlogo {
    display: none;
  }

  .dashboardservices {
    display: flex;
    justify-content: flex-start;
    min-width: 300px;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-left: 50px;
    /* margin-right: 50px; */
    margin-top: 0px;
  }

  .dashboardservicesmobile {
    display: none;
  }
  .dashboardservice {
    font-size: 20px;
  }

  .dashboardserviceheader {
    margin-left: 20px;
    font-size: 1.25rem;
  }

  .explorecontent {
    width: 25%;
  }

  .modalcontainer {
    min-width: 70%;
  }

  .email {
  }

  .radiologistbio {
  }

  /*****Service*****/

  .service {
    flex-direction: row;
    width: 75vw;
  }

  .servicedetail {
    width: 250px;
    height: 150px;
  }

  .partner {
    width: auto;
  }

  .prem {
    width: 20%;
  }

  .premless {
    width: 20%;
  }

  .vijayanand {
    width: 20%;
  }

  .vijayanandless {
    width: 20%;
  }

  .kiran {
    width: 20%;
  }

  .kiranless {
    width: 20%;
  }

  .radiologist {
    width: 20%;
  }

  .radiologistless {
    width: 20%;
  }

  .otherradiologist {
    width: 25%;
  }

  .contactdetails {
    flex-direction: row;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  /* 
  .hero {
    padding: 0px 80px;
  }
  .nav {
    padding: 20px 80px;
  }

  .modalcontainer {
    min-width: 50%;
  }

  .modalbody {
    padding: 0px 100px;
  }
  .maincontent {
    background: none;
  } */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
