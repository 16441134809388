.navbar {
  top: 0;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: sticky;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
  margin-left: 10px;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.main {
  margin: 10px;
  margin-top: 25px;
  padding: 10px;
  max-width: 850px;
  color: var(--primary-color);
}

.navheader {
  margin: 0;
}

.header {
  font-weight: bold;
  text-decoration: none;
  color: var(--primary-color);
  border-bottom: 2px solid #ccc;
}

.center {
  margin: 0px;
  font-weight: normal;
  font-size: 0.85rem;
  margin-bottom: 10px;
}

.study {
  background-color: var(--secondary-color);
  padding: 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  padding: 20px;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.85rem;
  flex-wrap: wrap;
}

.patient {
  width: 20%;
  margin-bottom: 16px;
}

.desktop {
  display: none;
}

.studydetail {
  width: 35%;
  margin-bottom: 16px;
}

.order {
  width: 35%;
  margin-bottom: 16px;
}

.orderinfo {
  margin-top: 30px;
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.contentheader {
  /* border-left: 4px solid var(--logo-color); */
  background: linear-gradient(
      -90deg,
      var(--secondary-logo-color),
      var(--logo-color)
    )
    var(--logo-color);
  padding: 10px 20px;
}

.contentmain {
  /*   font-size: 0.85rem;
 */
  margin-top: 10px;
}

.contentdetails {
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
}

.contentdetail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.files {
  margin-top: 10px;
}

.filesheader {
  border-left: 4px solid var(--logo-color);
  padding-left: 10px;
}

.contentdetailheader {
  font-weight: bold;
}

.related {
  margin-top: 30px;
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.reports {
  margin-top: 30px;
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  margin-bottom: 100px;
}

.nostudies {
  border-left: 4px solid var(--tertiary-color);
  padding: 5px 10px;
}

.footer {
  position: fixed;
  bottom: 0;
  padding: 10px;
  background-color: var(--primary-color);
  width: 100vw;
  color: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 0.9rem;
}

.email {
  display: flex;
}

.phone {
  display: flex;
  margin-left: 20px;
}

.phonedetail {
  text-decoration: none;
  color: var(--secondary-color);
}

.phonedetail:visited {
  color: var(--secondary-color);
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

  .navheader {
    margin-left: 10px;
  }

  .patient {
    width: 15%;
    margin-bottom: 8px;
  }

  .studydetail {
    width: 22%;
    margin-bottom: 8px;
  }

  .order {
    width: 22%;
    margin-bottom: 8px;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
