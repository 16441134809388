.main {
  margin: 10px 0px;
  padding: 20px;
  background-color: var(--secondary-color);
  max-width: 850px;
  color: var(--primary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.header {
  font-weight: bold;
  text-decoration: none;
  color: var(--primary-color);
  border-bottom: 2px solid #ccc;
}

.center {
  margin: 0px;
  font-weight: normal;
  font-size: 0.85rem;
  margin-bottom: 10px;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.85rem;
  flex-wrap: wrap;
}

.patient {
  width: 45%;
  margin-bottom: 16px;
}

.desktop {
  display: none;
}

.study {
  width: 45%;
  margin-bottom: 16px;
}

.order {
  width: 45%;
  margin-bottom: 16px;
}

.assigned {
  width: 45%;
  margin-bottom: 16px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .header {
    flex-direction: row;
  }

  .desktop {
    display: revert;
  }

  .patient {
    width: 15%;
    margin-bottom: 8px;
  }

  .study {
    width: 22%;
    margin-bottom: 8px;
  }

  .order {
    width: 22%;
    margin-bottom: 8px;
  }

  .assigned {
    width: 22%;
    margin-bottom: 8px;
  }

  .actions {
    margin-top: 0;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
