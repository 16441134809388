.main {
  margin: 15px 0px;
  border-left: 4px solid var(--primary-color);
  padding: 5px 10px;
  background-color: #f9f6e2;
  max-width: 900px;
  color: var(--primary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.header {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
}

.center {
  display: none;
  margin: 0px;
  font-weight: normal;
  padding-left: 5px;
}

.centermobile {
  margin: 0px;
  font-weight: normal;
  font-size: 0.85rem;
}

.details {
  display: flex;
  margin-top: 7px;
  font-size: 0.85rem;
  flex-wrap: wrap;
}

.patient {
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
}

.desktop {
  display: none;
}

.study {
  cursor: pointer;
  text-decoration: none;
  min-width: 150px;
  max-width: 150px;
  color: var(--primary-color);
}

.order {
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
  min-width: 150px;
  max-width: 150px;
}

.assigned {
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
  min-width: 150px;
  max-width: 150px;
  margin-top: 10px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  /*   max-width: 150px;
 */
  display: none;
  margin-top: 10px;
}

.actionsmobile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  /*   max-width: 150px;
 */
  margin-top: 10px;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .header {
    flex-direction: row;
  }
  .centermobile {
    display: none;
  }
  .center {
    display: revert;
  }
  .desktop {
    display: revert;
  }

  .study {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
    padding-right: 5px;
  }

  .order {
    min-width: 150px;
    max-width: 200px;
    width: 200px;
    padding-right: 5px;
  }

  .assigned {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
    margin-top: 0;
    padding-right: 5px;
  }

  .actions {
    min-width: 200px;
    max-width: 250px;
    margin-top: 0;
    display: revert;
  }
  .actionsmobile {
    display: none;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
