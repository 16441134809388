.main {
  margin: 10px 0px;
  /*   background-color: var(--secondary-color);
 */
  max-width: 850px;
  color: var(--primary-color);
  /*   box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
 */
}

.study {
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  padding: 20px;
}

.header {
  /* border-left: 4px solid var(--logo-color); */
  background: linear-gradient(
      -90deg,
      var(--secondary-logo-color),
      var(--logo-color)
    )
    var(--logo-color);
  padding: 10px 20px;
}

.details {
  padding: 10px 20px 10px 20px;
}

.order {
  margin-top: 30px;
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.related {
  margin-top: 30px;
}

.reports {
  margin-top: 30px;
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.report {
  margin-bottom: 15px;
  border-left: 4px solid var(--tertiary-color);
  padding-left: 10px;
}

.related {
  margin-top: 30px;
  background-color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

.rework {
  margin-bottom: 15px;
  border-left: 4px solid var(--tertiary-color);
  padding-left: 10px;
}

.reworkheader {
}

.reworkdetails {
  font-style: italic;
}

.nostudies {
  border-left: 4px solid var(--tertiary-color);
  padding: 5px 10px;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
