.component {
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 100vw;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .component {
    padding: 0px 20px 0px 20px;
    max-width: 90vw;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
