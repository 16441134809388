.main {
  max-width: 850px;
  color: var(--primary-color);
}
.templates {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.template {
  background-color: var(--secondary-color);
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  padding: 10px;
  border-left: 4px solid var(--logo-color);
  text-decoration: none;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 15px;
  font-family: var(--header-font);
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
