.main {
  /*   font-size: 0.85rem;
 */
  margin-top: 10px;
}
.details {
  display: flex;
  flex-direction: column;
}

.detail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.files {
  margin-top: 10px;
}

.filesheader {
  border-left: 4px solid var(--logo-color);
  padding-left: 10px;
}

.header {
  font-weight: bold;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .details {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .detail {
    /* width: 30%; */
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
