.main {
  max-width: 900px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.reports {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.report {
  background-color: var(--secondary-color);
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  padding: 10px;
  border-left: 4px solid var(--logo-color);
  text-decoration: none;
  color: var(--primary-color);
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.header {
  font-family: var(--header-font);
  font-weight: bold;
}

.additional {
  font-size: 0.85rem;
  margin-bottom: 10px;
}

.detail {
  font-size: 0.85rem;
  font-style: italic;
}

.actions {
  margin-left: 10px;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.noreports {
  border-left: 4px solid var(--primary-color);
  padding: 5px 10px;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

  .filter {
    justify-content: flex-start;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
