/*
TODO: Change the rgba with variable
*/

.navbar {
  top: 0;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: sticky;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
}

.header {
  margin: 0;
}

.username {
  font-size: 0.85rem;
  display: none;
  font-weight: bold;
  margin-left: 10px;
}

.mobileusername {
  font-weight: bold !important;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .logo {
    display: none;
  }

  .header {
    margin-left: 10px;
  }

  .username {
    display: revert;
  }
  .mobileusername {
    display: none !important;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
