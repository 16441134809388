.sidebar {
  width: 70px;
  min-width: 70px;
  min-height: 100vh;
  top: 0;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  z-index: 999;
  display: none;
  /*   box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px; */
}

.logo {
  width: 40px;
  height: 40px;
  margin: 10px 15px;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-top: 40px;
}

.action {
  color: var(--secondary-color);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-decoration: none;
  padding: 10px 15px;
}

.action:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.action:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.08);
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
}

@media (min-width: 641px) {
  .sidebar {
    display: block;
  }
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
