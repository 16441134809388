.main {
  max-width: 900px;
  display: flex;
  flex-direction: column;
}

.nostudies {
  margin: 10px 0px;
  border-left: 4px solid var(--primary-color);
  padding: 5px 10px;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

  .filter {
    justify-content: flex-start;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
