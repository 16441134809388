.bottombar {
  width: 100%;
  min-height: 7vh;
  top: 0;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  z-index: 999;
  position: sticky;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 72px;
  padding: 0px 20px;
}

.brand {
  font-family: var(--header-font);
  color: var(--tertiary-color);
  font-size: 20px;
  letter-spacing: 1px;
  padding-left: 10px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 5px 0px;
}

.action {
  color: var(--primary-color);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  padding: 0px 20px;
  height: 32px;
  line-height: 32px;
}

.action:hover {
  color: var(--primary-color);
}

.action:focus {
  outline: none;
  color: var(--primary-color);
}

.actionactive {
  border-bottom: 2px solid var(--primary-color);
}

.actionicon {
  font-size: 20px;
  height: 20px;
  width: 20px;
  color: var(--primary-color);
  padding: 0;
}

.actiontext {
  padding-left: 10px;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
}

@media (min-width: 641px) {
  .bottombar {
    display: none;
  }
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
}
